exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-new-atomic-method-pages-individual-profiles-ind-profile-js": () => import("./../../../src/newAtomicMethod/pages/individualProfiles/indProfile.js" /* webpackChunkName: "component---src-new-atomic-method-pages-individual-profiles-ind-profile-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-acknowledgements-js": () => import("./../../../src/pages/acknowledgements.js" /* webpackChunkName: "component---src-pages-acknowledgements-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-advisory-board-js": () => import("./../../../src/pages/advisoryBoard.js" /* webpackChunkName: "component---src-pages-advisory-board-js" */),
  "component---src-pages-fees-and-funding-js": () => import("./../../../src/pages/fees-and-funding.js" /* webpackChunkName: "component---src-pages-fees-and-funding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-programme-overview-js": () => import("./../../../src/pages/programme-overview.js" /* webpackChunkName: "component---src-pages-programme-overview-js" */),
  "component---src-pages-quality-control-js": () => import("./../../../src/pages/quality-control.js" /* webpackChunkName: "component---src-pages-quality-control-js" */)
}

